<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <div class="d-flex float-right">
          <el-button @click="$router.push({ name: 'Create Template' })" size="small" class="btn-primary" icon="el-icon-plus">Create new template</el-button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Template Name</th>
                <th>Category</th>
                <th>Preview</th>
                <th>Language</th>
                <th>Status</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in templateList" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.category_str || "-" }}</td>
                <td class="preview-body">{{ item.body_content_truncate }}</td>
                <td>
                  <el-popover
                    placement="right"
                    width="400"
                    trigger="hover">
                    <h6 v-if="item.header_content" class="mb-2">Header</h6>
                    <p v-if="item.header_content" class="mb-2">
                      <font-awesome :icon="item.header_content.icon"/>&nbsp; {{ item.header_content.text || item.header_content.type_str }}
                    </p>
                    <h6 class="mb-2">Body</h6>
                    <p class="mb-2" style="white-space: pre-line; overflow-wrap: break-word;">{{ item.body_content }}</p>
                    <template v-if="item.footer_content">
                      <h6 class="mb-2">Footer</h6>
                      <p class="mb-2" style="white-space: pre-line; overflow-wrap: break-word;">{{ item.footer_content.text }}</p>
                    </template>
                    <h6 v-if="item.button_content_mapped" class="mb-2">Buttons</h6>
                    <p class="mb-2">
                      <span v-for="(btn, ibtn) in item.button_content_mapped" :key="ibtn">
                        <font-awesome :icon="btn.icon"/>&nbsp; {{ btn.text }} ({{btn.type_str}})<br/>
                      </span>
                    </p>
                    <span @click="visible = !visible" slot="reference" style="padding: 6px" class="badge ml-1 badge-secondary cursor-pointer">
                      <flag :iso="item.flag_icon"/> {{ item.language.toUpperCase() }}
                    </span>
                  </el-popover>
                </td>
                <td><span style="padding: 6px" class="badge ml-1" :class="item.status_color">{{ item.status }}</span></td>
                <td>
                  <button class="btn btn-outline-danger btn-sm" @click="confirmDeleteTemplate(item)">
                    <font-awesome icon="trash"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty v-if="!templateList.length" description="No templates"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { upperFirst } from 'lodash';
import channelsAPI from '@/api/channels';
import notifApi from '@/api/notifications';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'BroadcastTemplatesPersonal',
  metaInfo: {
    title: 'Broadcast Templates',
  },
  data() {
    return {
      loaderStack: 0,
      loader: null,
      templateList: [],
      search: '',
      visible: false,
      page: 1,
      relation: {
        am: 'am',
        ar: 'sy',
        az: 'az',
        bn: 'bd',
        be: 'be',
        ca: 'es-ca',
        cs: 'cz',
        de: 'de',
        el: 'gr',
        en: 'gb',
        es: 'es',
        et: 'et',
        fa: 'ir',
        fr: 'fr',
        bg: 'bg',
        ha: 'ng',
        hi: 'in',
        hu: 'hu',
        hy: 'am',
        it: 'it',
        ja: 'jp',
        jv: 'id',
        km: 'km',
        ko: 'kr',
        lv: 'lv',
        mr: 'in',
        ms: 'my',
        pl: 'pl',
        pt: 'pt',
        ro: 'ro',
        ru: 'ru',
        sw: 'ke',
        ta: 'lk',
        te: 'in',
        th: 'th',
        tr: 'tr',
        uk: 'ua',
        uz: 'uz',
        vi: 'vn',
        zh: 'cn',
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    activeChannel() {
      return this.$store.state.channels.activeChannel;
    },
  },
  mounted() {
    if (!this.activeChannel._id || !this.activeWorkspace._id || this.activeChannel.workspace_id !== this.activeWorkspace._id) {
      this.$router.push('/broadcast-templates');
      return;
    }
    this.getList();
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async getList() {
      this.showLoader();
      await channelsAPI.templateList({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.activeChannel._id,
      }).then((response) => {
        this.templateList = response.data.templates.map((v) => {
          v.status_color = 'badge-success';
          if (!v.status) {
            v.status = 'APPROVED';
          }
          if (v.status.toLowerCase() === 'rejected') {
            v.status_color = 'badge-danger';
          } else if (v.status.toLowerCase() === 'pending') {
            v.status_color = 'badge-warning';
          }
          v.header_content = v.components.find((c) => c.type.toLowerCase() === 'header');
          if (v.header_content) {
            v.header_content.type_str = upperFirst(v.header_content.format.toLowerCase());
            v.header_content.icon = v.header_content.type_str.toLowerCase();
            if (v.header_content.type_str.toLowerCase() === 'document') {
              v.header_content.icon = 'file-alt';
            } else if (v.header_content.type_str.toLowerCase() === 'text') {
              v.header_content.icon = 'paragraph';
            }
          }

          v.body_content = v.components.find((c) => c.type.toLowerCase() === 'body').text;
          v.body_content_truncate = v.body_content;
          if (v.body_content.length > 35) {
            v.body_content_truncate = `${v.body_content.substr(0, 32)}...`;
          }

          v.footer_content = v.components.find((c) => c.type.toLowerCase() === 'footer');

          v.button_contents = v.components.find((c) => c.type.toLowerCase() === 'buttons');
          if (v.button_contents) {
            v.button_content_mapped = v.button_contents.buttons.map((btn) => {
              if (btn.type.toLowerCase() === 'phone_number') {
                btn.type_str = 'Phone Number';
                btn.icon = 'phone-alt';
              } else if (btn.type.toLowerCase() === 'url') {
                btn.type_str = 'Website URL';
                btn.icon = 'external-link-alt';
              } else if (btn.type.toLowerCase() === 'quick_reply') {
                btn.type_str = 'Quick Reply';
                btn.icon = 'comment';
              }
              return btn;
            });
          }

          v.flag_icon = this.relation[v.language.split('_')[0]];
          if (!v.flag_icon) {
            v.flag_icon = v.language.split('_')[0];
          }
          if (v.category) v.category_str = v.category.split('_').map((c) => upperFirst(c.toLowerCase())).join(' ');
          return v;
        });
      }).catch((err) => {
        console.log(err);
      });
      this.hideLoader();
    },
    confirmDeleteTemplate(item) {
      this.$confirm(this.$t('templates.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            try {
              const response = await notifApi.deleteTemplate({
                workspace_id: this.activeWorkspace._id,
                channel_id: this.activeChannel._id,
                template_name: item.name,
              });
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('labels.success.delete'),
                type: 'success',
              });

              this.page = 1;
              this.search = '';
              this.getList();
            } catch (error) {
              console.log(error);
            }

            instance.confirmButtonLoading = false;
            cb();
            return;
          }
          cb();
        },
      });
    },
  },
};
</script>
<style>
/* .preview-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
} */
</style>
